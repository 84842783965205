<template>
  <div class="user">
    <div class="search">
      <div v-if="managerAuth != 'PROMOTE'">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          type="text"
          placeholder="请输入内容"
          v-model="searchValue"
        ></el-input>
      </div>
      <el-date-picker
        v-model="times"
        type="datetimerange"
        align="right"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['12:00:00', '08:00:00']"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker>
      <div class="prices">
        <el-input
          type="text"
          placeholder="最小价格"
          v-model="minPrice"
        ></el-input>
        <span>-</span>
        <el-input
          type="text"
          placeholder="最大价格"
          v-model="maxPrice"
        ></el-input>
      </div>
      <el-button @click="handleReast">
        <span>重置</span>
      </el-button>
      <el-button type="primary" @click="handleSearch">
        <span>查询</span>
      </el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="{ 'text-align': 'center', background: '#f2f2f2' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column label="用户ID">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.userId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="文件名称" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.presTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.orderTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单编号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.orderNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单金额">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.orderStatus == "PENDING_PAYMENT"
              ? "待付款"
              : scope.row.orderStatus == "COMPLETED"
              ? "已完成"
              : "已取消"
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 汇总 -->
    <div class="totalData">
      <p>用户确权数量：{{ counts.presCount }}</p>
      <p>用户消费金额：{{ counts.amountCount }}</p>
    </div>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: "用户ID",
        },
        {
          value: 2,
          label: "订单编号",
        },
      ],
      value: 1,
      tableData: [],
      currentPage: 1,
      total: 0,
      searchValue: "", // 搜索内容
      times: "",
      minPrice: "",
      maxPrice: "",
      counts: {},
      managerAuth: "",
    };
  },
  methods: {
    // 重置
    handleReast() {
      this.value = 1;
      this.searchValue = "";
      this.times = "";
      this.minPrice = "";
      this.maxPrice = "";
      this.getList();
    },
    // 查询
    handleSearch() {
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 会员列表
    getList() {
      let params = {
        current: this.currentPage,
        pageSize: 10,
      };
      // 根据value判断当前搜索
      switch (this.value) {
        case 1:
          params.userId = this.searchValue;
          break;
        case 2:
          params.orderNum = this.searchValue;
          break;
        case 3:
          params.promoteId = this.searchValue;
          break;
      }
      params.startTime = this.times[0];
      params.endTime = this.times[1];
      params.mimAmount = this.minPrice;
      params.maxAmount = this.maxPrice;
      this.$axiosGet("order/queryOrderPage", params).then((res) => {
        console.log(res);
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 查询统计
    getCount() {
      this.$axiosGet("order/queryPromoteOrderCount", {}).then((res) => {
        this.counts = res.data;
      });
    },
  },
  created() {
    this.getList();
    this.getCount();
    this.managerAuth = localStorage.getItem("managerAuth");
    // if (this.managerAuth != 'PROMOTE') {
    //   this.options.push({
    //     value: 3,
    //     label: '白名单ID'
    //   })
    // }
  },
};
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  margin-bottom: 38px;

  .el-input {
    width: 200px;
    margin: 0 10px;
  }
  > div {
    display: flex;
    align-items: center;
  }
}

.pagination {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.el-button {
  padding: 10px 20px;
}

.prices {
  border: 1px solid #dcdfe6;
  background: #fff;
  border-radius: 4px;
  height: 38px;
  margin: 0 10px;

  /deep/.el-input__inner {
    border: none;
    height: 38px;
    text-align: center;
  }

  .el-input {
    margin: 0;
  }
}

.totalData {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px;

  p {
    margin-right: 30px;
    font-size: 16px;
    color: #333;
  }
}
</style>
